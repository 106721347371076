export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
}

const data: IMenuItem[] = [/*{
  id: 'dashboards',
  icon: 'iconsminds-shop-4',
  label: 'menu.dashboards',
  to: '/app/dashboards',
  subs: [{
    icon: 'iconsminds-arrow-inside-45',
    label: 'menu.default',
    to: '/app/dashboards/default'
  },
  {
    icon: 'simple-icon-pie-chart',
    label: 'menu.analytics',
    to: '/app/dashboards/analytics'
  },
  {
    icon: 'simple-icon-basket-loaded',
    label: 'menu.ecommerce',
    to: '/app/dashboards/ecommerce'
  },
  {
    icon: 'simple-icon-doc',
    label: 'menu.content',
    to: '/app/dashboards/content'
  }
  ]
},*/
{
  id: 'schedule',
  icon: 'iconsminds-calendar-4',
  label: 'menu.schedule',
  to: '',
  subs: [{
    icon: 'iconsminds-business-man',
    label: 'menu.schedule-business',
    to: '',
    subs: [{
      icon: 'iconsminds-file-clipboard',
      label: 'menu.schedule-business-appointment',
      to: '/app/schedule/business/schedule-business-appointment'
    },
    {
      icon: 'iconsminds-check',
      label: 'menu.schedule-business-task',
      to: '/app/schedule/business/schedule-business-task'
    }]
  },
  {
    icon: 'iconsminds-male',
    label: 'menu.schedule-personal',
    to: '',
    subs: [{
      icon: 'iconsminds-file-clipboard',
      label: 'menu.schedule-personal-appointment',
      to: '/app/schedule/personal/schedule-personal-appointment'
     },
     {
      icon: 'iconsminds-check',
      label: 'menu.schedule-personal-task',
      to: '/app/schedule/personal/schedule-personal-task'
    }]
  },
  {
    icon: 'iconsminds-project',
    label: 'menu.schedule-process',
    to: '',
    subs: [{
      icon: 'iconsminds-mail-send',
      label: 'menu.schedule-process-identification',
      to: '/app/schedule/schedule-process/schedule-process-identification'
    }
    ]
  }
  ]
},
{
  id: 'legal-information',
  icon: 'iconsminds-newspaper',
  label: 'menu.legal-information',
  to: '',
  subs: [{
    icon: 'iconsminds-soccer-ball',
    label: 'menu.legal-information-judicial-sphere',
    to: '',
    subs: [{
      icon: 'iconsminds-livejournal',
      label: 'menu.legal-information-news',
      to: '/app/legal-information/judicial-sphere/legal-information-news'
    },
    {
      icon: 'iconsminds-radio',
      label: 'menu.legal-information-decisions',
      to: '/app/legal-information/judicial-sphere/legal-information-decisions'
    },
    {
      icon: 'iconsminds-id-card',
      label: 'menu.legal-information-sumulas',
      to: '/app/legal-information/judicial-sphere/legal-information-sumulas'
    }]
  }]
},
{
  id: 'attorney',
  icon: 'iconsminds-business-man',
  label: 'menu.attorney',
  to: '',
  subs: [{
    icon: 'iconsminds-play-music',
    label: 'menu.attorney-audio-function',
    to: '',
    subs: [{
      icon: 'iconsminds-record',
      label: 'menu.attorney-audio-function-save',
      to: ''
    },
    {
      icon: 'iconsminds-pen',
      label: 'menu.attorney-audio-function-transcription',
      to: ''
    },
    {
      icon: 'iconsminds-cursor-select',
      label: 'menu.attorney-audio-function-text',
      to: ''
    },
    {
      icon: 'iconsminds-books',
      label: 'menu.attorney-audio-function-topic',
      to: ''
    },
    {
      icon: 'iconsminds-magnifi-glass',
      label: 'menu.attorney-audio-function-search-param',
      to: ''
    },
    {
      icon: 'iconsminds-on-off-2',
      label: 'menu.attorney-audio-function-fact-search',
      to: ''
    },
    {
      icon: 'iconsminds-arrow-merge',
      label: 'menu.attorney-audio-function-conciliation',
      to: ''
    }
    ]
  },
  {
    icon: 'iconsminds-add-user',
    label: 'menu.attorney-client-register',
    to: '/app/dashboards/analytics',
    subs: [{
      icon: 'iconsminds-reload-1',
      label: 'menu.attorney-client-register-active-pole',
      to: ''
    },
    {
      icon: 'iconsminds-repeat-1',
      label: 'menu.attorney-client-register-passive-pole',
      to: ''
    }
    ]
  },
  {
    icon: 'iconsminds-receipt-4',
    label: 'menu.attorney-service-roadmap',
    to: '',
    subs: [{
      icon: 'iconsminds-gey',
      label: 'menu.attorney-service-roadmap-customer',
      to: ''
    },
    {
      icon: 'iconsminds-road-2',
      label: 'menu.attorney-service-roadmap-time',
      to: ''
    }
    ]
  },
  {
    icon: 'iconsminds-map2',
    label: 'menu.process-roadmap',
    to: '',
    subs: [{
      icon: 'iconsminds-folder-block',
      label: 'menu.consolidation-facts',
      to: '/app/attorney/process-roadmap/consolidation-facts'
    }
    ]
  },
  {
    icon: 'iconsminds-radio',
    label: 'menu.attorney-electronic-process',
    to: '',
    subs: [{
      icon: 'iconsminds-check',
      label: 'menu.attorney-electronic-process-checklist',
      to: ''
    },
    {
      icon: 'iconsminds-management',
      label: 'menu.attorney-electronic-process-demand',
      to: ''
    },
    {
      icon: 'iconsminds-tablet-3',
      label: 'menu.attorney-electronic-process-table',
      to: ''
    },
    {
      icon: 'iconsminds-yes',
      label: 'menu.attorney-electronic-process-checkin',
      to: ''
    }
    ]
  },
  {
    icon: 'iconsminds-control',
    label: 'menu.attorney-controls',
    to: '',
    subs: [{
      icon: 'iconsminds-money-bag',
      label: 'menu.attorney-controls-fee',
      to: ''
    },
    {
      icon: 'iconsminds-yes',
      label: 'menu.attorney-controls-process',
      to: ''
    }
    ]
  },
  {
    icon: 'iconsminds-library',
    label: 'menu.attorney-digital-certification',
    to: '',
    subs: [{
      icon: 'iconsminds-clock-back',
      label: 'menu.attorney-digital-certification-checkin',
      to: ''
    }
    ]
  }
  ]
},
{
  id: 'registers',
  icon: 'iconsminds-id-card',
  label: 'menu.registers',
  to: '',
  subs: [{
    icon: 'iconsminds-monitoring',
    label: 'menu.registers-active-pole',
    to: '/app/registers/registers-active-pole'
  },
  {
    icon: 'iconsminds-optimization',
    label: 'menu.registers-passive-pole',
    to: '/app/registers/registers-passive-pole'
  },
  {
    icon: 'iconsminds-management',
    label: 'menu.registers-process',
    to: '/app/registers/registers-process'
  }
  ]
},
{
  id: 'financial-control',
  icon: 'iconsminds-money-bag',
  label: 'menu.financial-control',
  to: '',
  subs: [{
    icon: 'iconsminds-address-book-2',
    label: 'menu.financial-control-index',
    to: '',
    subs: [{
      icon: 'iconsminds-power-cable',
      label: 'menu.financial-control-index-method',
      to: '/app/dashboards/default'
    },
    {
      icon: 'iconsminds-power-cable',
      label: 'menu.financial-control-index-compound',
      to: '/app/dashboards/default'
    },
    {
      icon: 'iconsminds-power-cable',
      label: 'menu.financial-control-index-simple',
      to: '/app/dashboards/default'
    }
    ]
  },
  {
    icon: 'iconsminds-money-bag',
    label: 'menu.financial-control-tax',
    to: '',
    subs: [{
      icon: 'iconsminds-power-cable',
      label: 'menu.financial-control-tax-method',
      to: '/app/dashboards/default'
    },
    {
      icon: 'iconsminds-power-cable',
      label: 'menu.financial-control-tax-compound',
      to: '/app/dashboards/default'
    },
    {
      icon: 'iconsminds-power-cable',
      label: 'menu.financial-control-tax-simple',
      to: '/app/dashboards/default'
    }
   ]
  },
  {
    icon: 'iconsminds-road-2',
    label: 'menu.financial-control-foundations',
    to: '',
    subs: [{
      icon: 'iconsminds-power-cable',
      label: 'menu.financial-control-foundations-legislations',
      to: '/app/dashboards/default'
    },
    {
      icon: 'iconsminds-power-cable',
      label: 'menu.financial-control-foundations-economic-rules',
      to: '/app/dashboards/default'
    },
    {
      icon: 'iconsminds-power-cable',
      label: 'menu.financial-control-foundations-financy-rules',
      to: '/app/dashboards/default'
    }
    ]
  }
  ]
},
{
  id: 'procedural-strategies',
  icon: 'iconsminds-memory-card-2',
  label: 'menu.procedural-strategies',
  to: '',
  subs: [{
    icon: 'iconsminds-biotech',
    label: 'menu.procedural-strategies-scorecard',
    to: '',
    subs: [{
      icon: 'iconsminds-power-cable',
      label: 'menu.procedural-strategies-scorecard-process',
      to: '/app/procedural-strategies/scorecard/procedural-strategies-scorecard-process'
    },
    {
      icon: 'iconsminds-refresh',
      label: 'menu.procedural-strategies-scorecard-evolution',
      to: '/app/procedural-strategies/scorecard/procedural-strategies-scorecard-evolution'
    }
    ]
  }
  ]
},
{
  id: 'ged',
  icon: 'iconsminds-files',
  label: 'menu.ged',
  to: '',
  subs: [{
    icon: 'iconsminds-folder-cloud',
    label: 'menu.ged-docs',
    to: '/app/ged/ged-docs'
  }
  ]
},
{
  id: 'crm',
  icon: 'iconsminds-business-mens',
  label: 'menu.crm',
  to: '',
  subs: [{
    icon: 'iconsminds-idea',
    label: 'menu.crm-mkt',
    to: '/app/crm/crm-mkt'
  }
  ]
},
{
  id: 'bi',
  icon: 'iconsminds-student-hat',
  label: 'menu.bi',
  to: '',
  subs: [{
    icon: 'iconsminds-office-lamp',
    label: 'menu.bi-strategy',
    to: '/app/bi/bi-strategy'
  }
  ]
},
/*{
  id: 'mobile',
  icon: 'iconsminds-smartphone-3',
  label: 'menu.mobile',
  to: '',
  subs: [{
    icon: 'iconsminds-big-data',
    label: 'menu.mobile-platform',
    to: '/app/mobile/mobile-platform'
  }
  ]
},*/
{
  id: 'histogram',
  icon: 'iconsminds-prater',
  label: 'menu.histogram',
  to: '',
  subs: [{
    icon: 'iconsminds-bar-chart-4',
    label: 'menu.histogram-graphs-tables',
    to: '/app/histogram/histogram-graphs-tables',
  },
  {
    icon: 'iconsminds-dvd',
    label: 'menu.histogram-procedural-steps',
    to: '',
    subs: [{
      icon: 'iconsminds-clock-forward',
      label: 'menu.histogram-procedural-steps-timeline',
      to: '/app/histogram/histogram-procedural-steps-timeline'
    },
    ]
  }
  ]
},
{
  id: 'admin',
  icon: 'iconsminds-wrench',
  label: 'menu.admin',
  to: '',
  subs: [{
    icon: 'iconsminds-clock-back',
    label: 'menu.schedule',
    to: '',
    subs: [{
      icon: 'iconsminds-folder-cloud',
      label: 'menu.admin-schedule-commitment-type',
      to: '/app/admin/schedule/admin-schedule-commitment-type'
    }
    ]
  }
  ]
}
];
export default data;
